<template>
  <v-container
    id="register"
    class="fill-height justify-center"
    tag="section"
  >
    <div class="d-flex justify-center flex-wrap">
        <v-card max-width="400" max-height="300" class="pa-3 mr-4">
          <div class="display-2 my-5 text-center">
            Sign up for your free account!
          </div>
          <v-card-text>
            <p style="line-height: 24px; text-align: justify;">
              With the secure dashboard you can get information about your organizations cyber risk. Which users are high risk, what settings are insecure, which users have access to applications?
            </p>
          </v-card-text>
          <div class="d-flex justify-center">
            <v-btn color="main" href="https://revampcybersecurity.com/demo" target="_blank">Demo</v-btn>
          </div>
        </v-card>
        <v-slide-y-transition appear>
          <v-card
            light
            max-width="100%"
            width="400"
            class="px-5 py-5"
          >
           
            <v-card-title class="justify-center display-2">
              Register
            </v-card-title>

            <v-form
              ref="form"
              v-model="valid"
              class="text-center"
            >
              <v-text-field
                v-model="form.first_name"
                :rules="[rules.required]"
                :loading="loading"
                class="mt-5 mb-3"
                hide-details="auto"
                color="secondary"
                label="Please enter your first name"
                prepend-icon="mdi-account-outline"
                @keyup.enter="submit"
                required
              />

              <v-text-field
                v-model="form.last_name"
                :rules="[rules.required]"
                :loading="loading"
                class="mt-5 mb-3"
                hide-details="auto"
                color="secondary"
                label="Please enter your last name"
                prepend-icon="mdi-account-outline"
                @keyup.enter="submit"
                required
              />

              <v-text-field
                v-model="form.email"
                :rules="[rules.required, rules.email]"
                :loading="loading"
                class="mt-5 mb-3"
                hide-details="auto"
                color="secondary"
                label="Please enter your email address."
                prepend-icon="mdi-email-outline"
                @keyup.enter="submit"
                required
              />

              <v-text-field
                v-model="form.company_id"
                :rules="[rules.required, rules.spaceAllowed, rules.validCompanyName]"
                :loading="loading"
                class="mt-3"
                hide-details="auto"
                color="secondary"
                label="Please enter your company domain correctly."
                prepend-icon="mdi-web"
                @keyup.enter="submit"
                required
              />

              <v-text-field
                v-model="form.company_name"
                :loading="loading"
                class="mt-3 mb-7"
                hide-details="auto"
                color="secondary"
                label="Company Name."
                prepend-icon="mdi-application"
                @keyup.enter="submit"
                required
              />

              <v-checkbox
                v-model="form.accept"
              >
                <template v-slot:label>
                  <div>
                    By Creating an account, I agree to Revamp Cybersecurity 
                    <a
                      target="_blank"
                      href="https://revampcybersecurity.com/msa/"
                      @click.stop
                    >
                      EULA
                    </a>
                    and
                    <a
                      target="_blank"
                      href="https://revampcybersecurity.com/privacy-policy/"
                      @click.stop
                    >
                      Privacy Policy
                    </a>
                  </div>
                </template>
              </v-checkbox>

              <v-btn
                :loading="loading"
                color="main"
                class="display-1"
                :disabled="!valid || loading || !form.accept"
                @click="submit"
              >
                Create account
              </v-btn>
              <div class="text-center grey--text mt-2 body-1 font-weight-light">
                If you already have an account, please <a href="javascript:;" @click="gotoLogin">login</a>
              </div>
            </v-form>
          </v-card>
        </v-slide-y-transition>
    </div>
  </v-container>
</template>

<script>
  import { BASE_API, Get, Post } from '@/api'
  import { DOMAIN_LIST } from '@/util'
  import { mapState } from 'vuex'

  export default {
    name: 'PagesRegister',

    data () {
      const defaultForm = Object.freeze({
        first_name: '',
        last_name: '',
        email: '',
        company_id: '',
        accept: false
      })

      return {
        valid: true,
        socials: [
          {
            href: '#',
            icon: 'mdi-google',
            iconColor: '#dd4b39',
          },
        ],
        errorMessages: {
          username: '',
          email: {
            required: false,
            invalid: false,
            business: false
          },
          password: ''
        },
        timeout: 10000,
        terms: false,
        defaultForm,
        form: Object.assign({}, defaultForm),
        rules: {
          required: value => {
            return !!value || 'This field is required.'
          },
          counter: value => value.length >= 6 || 'Min 6 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
          validEmail: value => {
            const domain = value.split('@')[1]
            return value.includes('@') && !DOMAIN_LIST.includes(domain.toLowerCase())  || 'Please enter a business email'
          },
          spaceAllowed: v => {
            return  (v.split(' ').length <= 1 || 'Space is not allowed')
          },
          validCompanyName: v => {
            return (v.split('.').length > 1 || 'Invalid domain')
          }
        },
      }
    },

    computed: {
      ...mapState('auth', ['loading', 'error']),
      ...mapState('snackbar', ['snack'])
    },

    watch: {
      error(val) {
        if (val == 'success') {
          this.$router.push({name: 'VerifyEmail'})
        }
      }
    },

    methods: {
      gotoLogin () {
        this.$router.push({ name: "Login" });
      },
      passwordCheck () {
        let checkPassword = this.form.password !== this.form.password1
          ? 'The password does not match.'
          : ''
        return !!checkPassword
      },
      async submit () {
        this.$refs.form.validate()
        if (!this.valid) {
          return
        }
        this.form.status = 'Verify'
        this.$store.dispatch('auth/register', this.form)
      }
    },
  }
</script>

<style lang="sass">
  #register
    .v-list-item__subtitle
      -webkic-line-clamp: initial
      -webkit-box-orient: initial
</style>
